@import "../../styles/responsive/breakpoints.scss";
@import "../../styles/index.scss";

.projects {
	min-height: 100vh;
	width: 100%;
	box-sizing: border-box;
	// scroll-snap-align: start;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.projects-title {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
		align-items: center;
		visibility: hidden;

		p {
			font-size: var(--subtitle);
			width: 60%;

			@include screenxs {
				width: 100%;
			}

			@include screenl {
				width: 50%;
			}
		}

		.all-projects-button {
			@include primaryBigButton;
			width: 13em;
			margin-top: var(--vertical-spacing-blocks);
			margin-bottom: var(--vertical-spacing-paragraphs);
		}
	}

	.project-cards {
		justify-items: center;
		align-items: center;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: var(--vertical-spacing-paragraphs);

		@include screenxs {
			grid-template-columns: 1fr;
			grid-gap: var(--vertical-spacing-blocks);
		}

		@include screenl {
			grid-template-columns: 1fr 1fr 1fr;
		}

		@include screenxl {
			grid-gap: var(--vertical-spacing-paragraphs);
		}
	}
}
