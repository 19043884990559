@import "../../styles/responsive/breakpoints.scss";
@import "../../styles/index.scss";

.contact-hero {
	display: flex;
	height: 150px;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	position: relative;

	@include screenxs {
		text-align: center;
	}

	@include screenxl {
		text-align: start;
	}
}
