@import "../../styles/responsive/breakpoints.scss";

nav {
	top: 0;
	left: 0;
	z-index: 9999;
	height: 8vh;
	width: 100vw;
	display: flex;
	align-items: center;
	position: fixed;
	justify-content: space-between;

	.logo {
		margin: 0 2em;
		span {
			cursor: pointer;
			font-size: 25px;
			font-weight: 900;

			&::after {
				content: "";
				display: block;
				width: 0;
				height: 2px;
				background: #fff;
				transition: width 0.3s;
			}

			&:hover::after {
				width: 100%;
				transition: width 0.3s;
			}
		}
	}

	.nav-items {
		@include screenxs {
			display: none;
		}

		@include screenl {
			display: block;
		}

		ul {
			display: flex;
			justify-content: space-evenly;
			list-style: none;

			li {
				margin: 0 2em;
				span {
					font-weight: 900;
					font-size: 20px;

					&::after {
						content: "";
						display: block;
						width: 0;
						height: 2px;
						background: #fff;
						transition: width 0.3s;
					}

					&:hover::after {
						width: 100%;
						transition: width 0.3s;
					}
				}

				cursor: pointer;
			}
		}
	}

	.hamburger-menu {
		margin: 0 2em;
		width: 30px;
		height: 30px;
		display: none;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		cursor: pointer;
		transition: all 0.3s linear;
		transform-origin: center;

		@include screenxs {
			display: flex;
		}

		@include screenl {
			display: none;
		}

		&:hover {
			scale: 0.8;
		}

		span {
			width: 100%;
			height: 3px;
			background-color: white;
			transition: all 0.3s linear;
		}
	}

	.hamburger-close {
		padding-right: 2em;
		width: 50px;
		height: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-evenly;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		span {
			width: 100%;
			height: 5px;
			background-color: white;
			transition: all 0.3s linear;
			display: flex;
			flex-direction: column;
			align-items: center;
			transform-origin: 5px;

			&:nth-child(1) {
				transform: rotate(45deg);
			}

			&:nth-child(3) {
				transform: rotate(-45deg);
			}

			&:nth-child(2) {
				width: 100%;
				opacity: 0;
				transform: translateX(20px);
			}
		}
	}
}

.link-active {
	&::after {
		content: "";
		display: block;
		width: 100%;
		height: 2px;
		background: #fff;
		transition: width 0.3s;
	}
}
