@import "../../styles/responsive/breakpoints.scss";
@import "../../styles/index.scss";

.contact-instructions {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	box-sizing: border-box;

	h3 {
		text-align: start;
		width: 450px;

		@include screenl {
			display: block;
		}
	}

	@include screenxs {
		align-items: center;
		text-align: center;
		margin: 0 auto;
		width: 100%;
	}

	@include screenl {
		text-align: start;
		align-items: flex-start;
		width: 50%;
	}

	@include screenxl {
		display: flex;
		flex-direction: column;
		margin: 0;
		align-items: flex-start;
		width: 50%;
	}

	.socials-row {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
		gap: 2rem;
		padding-bottom: 2rem;

		@include screenxxs {
			justify-content: center;
		}

		@include screenl {
			justify-content: flex-start;
		}

		ol {
			margin: 0;
			padding: 0;

			&:hover {
				opacity: 0.5;
			}
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			margin: var(--vertical-spacing-blocks) 0;
			h3 {
				@include screenxs {
					width: 100%;
				}

				@include screenl {
					text-align: start;
					align-items: flex-start;
				}

				@include screenxl {
					width: 80%;
					text-align: start;
				}
			}

			p {
				width: 80%;

				@include screenxs {
					text-align: justify;
					width: 100%;
				}

				@include screenl {
					width: 70%;
					text-align: justify;
				}

				@include screenxl {
					width: 80%;
					margin: 0;
					text-align: start;
				}
			}
		}
	}
}
