@import "../../styles/responsive/breakpoints.scss";
@import "../../styles/index.scss";

.contact {
	min-height: 100vh;
	width: 100%;
	color: white;
	padding-bottom: 5rem;

	margin: 0 auto;

	@include screenxs {
		max-width: 320px;
		padding-left: 10px;
		padding-right: 10px;
	}

	@include screens {
		max-width: 375px;
		padding-left: 10px;
		padding-right: 10px;
	}

	@include screenm {
		// 	scroll-snap-align: start;

		max-width: 768px;
		padding-left: 20px;
		padding-right: 20px;
	}

	@include screenl {
		max-width: 1024px;
	}

	@include screenxl {
		max-width: 1200px;
		padding-left: 50px;
		padding-right: 50px;
	}

	@include screenxxl {
		max-width: 1440px;
	}

	@include screenxs {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: center;
	}

	@include screenxl {
		align-items: unset;
		flex-direction: row;
		justify-content: center;
	}

	.contact-part {
		display: flex;
		align-items: baseline;
		justify-content: space-evenly;
		box-sizing: border-box;

		@include screenxs {
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		@include screenl {
			flex-direction: row;
			align-items: baseline;
		}

		@include screenxl {
			justify-content: space-between;
			display: flex;
			align-items: baseline;
			flex-direction: row;
			margin: 0;
		}
	}

	.form {
		display: flex;
		flex-direction: column;

		@include screenxs {
			width: 100%;
			padding-bottom: 2em;
		}

		@include screenl {
			padding-bottom: unset;
			width: 50%;
		}

		@include screenxl {
			margin-top: 3em;
			justify-content: center;
			align-items: flex-start;
			width: 50%;
		}

		.success-form,
		.fail-form {
			display: flex;
			align-items: flex-start;
			margin-top: 2em;
			flex-direction: column;
			justify-content: center;
			text-align: start;

			span {
				margin-bottom: 2em;
			}

			@include screenxs {
				margin-top: 5em;
				width: 100%;
				justify-content: center;
				align-items: center;
			}

			@include screenxl {
				display: flex;
				align-items: flex-start;
				margin-top: 2em;
				flex-direction: column;
				justify-content: flex-start;
				text-align: start;
			}
		}

		h1,
		span {
			@include screenxl {
				text-align: start;
			}
		}

		form {
			display: grid;
			grid-template-columns: 1fr 1fr;
			margin-top: var(--vertical-spacing-blocks);

			@include screenxs {
				display: flex;
				width: 100%;
				flex-direction: column;
				justify-content: center;
			}

			@include screenl {
				width: 100%;
				margin-left: auto;
				margin-right: auto;
				grid-template-columns: 1fr 1fr;
			}

			@include screenxl {
				display: grid;
				grid-template-columns: 1fr 1fr;
				grid-gap: var(--vertical-spacing-blocks);
				width: 100%;
			}

			div {
				display: flex;
				flex-direction: column;

				@include screenxs {
					margin-bottom: var(--vertical-spacing-blocks);
				}
				@include screenxl {
					margin-bottom: 0;
				}

				label {
					font-weight: 600;
					margin-bottom: 3px;

					@include screenxs {
						margin-bottom: 5px;
					}
				}

				input {
					border-radius: 10px;
					height: 35px;
					background: none;
					border: 2px solid white;
					color: white;
					padding-left: 1em;
					transition: all 0.3s ease-in-out;
					font-size: var(--body);

					&:focus {
						outline: thin dotted;
						border: 2px solid rgb(144, 240, 144);
					}
				}

				span {
					color: rgb(163, 73, 73);
				}

				&:nth-child(6) {
					grid-column: 1 / 3;

					textarea {
						padding: 1em;
						font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
							Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
							sans-serif;
						border-radius: 10px;
						height: 35px;
						background: none;
						border: 2px solid white;
						color: white;
						height: 100px;
						scrollbar-arrow-color: white;

						&:focus {
							outline: thin dotted;

							border: 2px solid rgb(144, 240, 144);
						}

						@include screenxxs {
							padding: 0;
							padding-left: 1em;
							padding-top: 1em;
						}
					}
				}
			}

			.button-submit {
				grid-column: 1 / 3;
				border: none;
				@include primaryBigButton;
				width: 100%;
				color: white;
			}
		}
	}
}

footer {
	margin-bottom: 5px;
}
