@import "../../styles/responsive/breakpoints.scss";

.menu {
	position: fixed;
	color: white;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	z-index: 100;
	background-color: black;
	transition: all 0.5s ease-in-out;

	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	align-items: flex-start;

	display: none;

	@include screenm {
		display: flex;
	}

	@include screenxxs {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@include screenxs {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@include screens {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.menu-items {
		z-index: 101;
		margin: 0 auto;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			h1 {
				cursor: pointer;
				font-size: 50px;
				font-weight: 900;
				display: inline-block;
				margin: 1rem 0;

				&::after {
					content: "";
					display: block;
					width: 0;
					height: 2px;
					background: #fff;
					transition: width 0.3s;
				}

				&:hover::after {
					width: 100%;
					transition: width 0.3s;
				}
			}
		}
	}

	img {
		overflow: hidden;
		position: absolute;
		object-fit: cover;
		opacity: 50%;
		width: 150%;
		height: 150%;
		left: 0;
		top: 0;
		scale: 1.2;
		transition: all 0.3s ease-in-out;
		background-color: black;
	}

	.kenburn {
		overflow: hidden;
		position: absolute;
		object-fit: cover;
		opacity: 50%;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		scale: 1.2;
		transition: all 0.3s ease-in-out;
		animation: backgroundEffect 30s infinite;
	}
}

.menu2 {
	position: fixed;
	color: white;
	width: 100%;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 90;
	background-color: rgb(255, 255, 255);
	transition: all 0.5s ease-in-out;
}

.open {
	opacity: 1;
	overflow: hidden;
	transform: translateX(0);
}

.closed {
	opacity: 0;
	transform: translateX(100%);
}

@keyframes backgroundEffect {
	0% {
		filter: blur(5px);
		opacity: 0;
		left: 0%;
		top: 0%;
	}
	5% {
		filter: blur(2px);
		opacity: 1;
	}
	25% {
		filter: blur(0px);
		left: 10px;
		top: 5px;
		background-position: 40% 10%;
	}
	50% {
		left: 0px;
		top: 10px;
		background-position: 0 10%;
	}
	75% {
		left: 10px;
		top: 40px;
		background-position: 10% 40%;
	}
	100% {
		left: 0px;
		top: 0px;
		background-position: 0% 0%;
	}
}

.link-active {
	color: red;
}
