@import "../../styles/responsive/breakpoints.scss";
@import "../../styles/index.scss";

.full-page-project-detail {
	background-color: black;
	padding: 5em 0;
}
.project-detail-wrapper {
	position: relative;
	background-color: black;
	display: flex;
	flex-direction: column;
	gap: 5em;
	margin-bottom: 10em;
}

.project-detail-close-modal {
	position: fixed;
	z-index: 3000;
	top: 5%;
	right: 5%;
	cursor: pointer;
	background: black;
	border-radius: 50%;
	font-weight: 500;
	font-size: 25px;
	color: white;
	height: 50px;
	width: 50px;
	border: 2px solid white;
	transition: all 0.3s ease;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		color: black;
		background: white;
	}

	@include screens {
		height: 50px;
		width: 50px;
	}
}

.p-landing {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid rgba(227, 227, 227, 0.43);
	padding-bottom: 5em;
	width: 100%;

	@include screenxs {
		text-align: justify;
		flex-direction: column-reverse;
	}

	@include screenxl {
		flex-direction: row;
	}

	.p-text {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		width: 40%;

		@include screenxs {
			width: 100%;
			text-align: center;
		}

		@include screenxl {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			width: 40%;
			align-items: flex-start;
		}

		h1 {
			width: 100%;
			text-align: start;
		}

		p {
			font-size: 20px;
			width: 100%;

			@include screenxs {
				text-align: justify;
			}
		}

		.p-info {
			margin-top: 2em;
			display: flex;
			font-weight: 600;

			div {
				width: 100%;
				margin-right: 5em;

				@include screenxs {
					margin: 0 auto;
					text-align: center;
				}

				@include screenxl {
					width: 100%;
					margin: 0;
					text-align: start;
				}
			}
		}

		.p-buttons {
			display: flex;
			margin-top: 5em;
			gap: 2em;

			@include screenxs {
				justify-content: center;
				flex-direction: column;
				align-items: center;
			}

			@include screenm {
				flex-direction: row;
				align-items: center;
			}

			@include screenxl {
				align-items: flex-start;
				flex-direction: row;
				align-items: center;
			}

			.project-cards-single-button {
				@include secondaryBigButton;
			}

			.project-cards-single-button-2 {
				@include primaryBigButton;
			}
		}
	}

	.p-image {
		img {
			object-fit: contain;
		}
	}
}

.project-detail-story {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3rem;

	.p-story-img {
		background-color: rgb(0, 0, 0);
		background: black;

		@include screenxs {
			width: 100%;
		}

		@include screenxl {
			width: 50%;
		}

		img {
			object-fit: contain;
			height: 100%;
			width: 100%;
		}
	}

	.p-story-text {
		display: flex;
		flex-direction: column;

		h2 {
			font-size: 30px;
			margin-bottom: 5px;
			width: 100%;
		}

		@include screenxxs {
			width: 320px;
		}

		@include screenm {
			width: 750px;
		}

		ul {
			text-align: start;
			li {
				margin: 0;
				a {
					font-size: 20px;
					margin: 15px 0;
					display: inline-block;
					&::after {
						content: "";
						display: block;
						width: 0;
						height: 2px;
						background: #fff;
						transition: width 0.3s;
					}

					&:hover::after {
						width: 100%;
						transition: width 0.3s;
					}
				}
			}
		}
	}
}
