@import "../../styles/responsive/breakpoints.scss";
@import "../../styles/index.scss";

.background-black {
	width: 100%;

	background-image: linear-gradient(rgb(0, 0, 0), rgba(0, 0, 0, 0.033));
}

.background-black-reverse {
	width: 100%;
	background-image: linear-gradient(
		rgba(0, 0, 0, 0),
		rgba(0, 0, 0, 0.334),
		rgb(0, 0, 0)
	);
}
.landing-title {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	@include screenxs {
		text-align: center;
		justify-content: center;
	}

	.landing-title-text-name {
		font-size: 5em;
		pointer-events: none;
		user-select: none; /* supported by Chrome and Opera */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */

		span {
			visibility: hidden;
			font-size: inherit;
			font-weight: inherit;
		}

		@include screenm {
			font-size: 10em;
		}
	}

	.landing-title-text {
		display: flex;
		flex-direction: column;
		align-items: center;

		.landing-title-text-title {
			visibility: hidden;
			margin-top: var(--vertical-spacing-blocks) / 2;

			@include screenm {
				width: 85%;
			}
		}
	}

	.landing-title-cta-buttons {
		visibility: hidden;
		opacity: 1;
		display: grid;
		margin-top: calc(var(--vertical-spacing-blocks) * 2);
		grid-template-columns: 1fr 1fr;
		align-items: center;
		grid-gap: var(--vertical-spacing-blocks);

		@include screenxs {
			align-items: center;
			justify-items: center;
			grid-template-columns: 1fr;
		}
		@include screenm {
			grid-template-columns: 1fr 1fr;
		}

		.landing-title-cta-contact-me {
			@include primaryBigButton;
		}

		.landing-title-resume {
			@include secondaryBigButton;
		}
	}
}
